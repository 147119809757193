<template>
    <div>
      <base-header class="pb-6" type="primary">
        <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
      </base-header>
  
      <div class="container-fluid mt-6"
        :class="{'page-loading': apiStatus === 'loading'}"
      >
        <div class="alert alert-success alert-dismissible" v-if="postStatus === 'success'">
          Poprawnie zapisano dane
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="card">
              <div class="card-header">
                <h3>Dane sukskrybenta</h3>
              </div>
              <div class="card-body">
                <base-input
                  type="text"
                  v-model="email"
                  label="Adres email"
                  placeholder="Wprowadź adres email subskrybenta"
                />
                <div class="row">
                  <div class="col-6">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                      <span>Aktywny</span>
                      <base-switch 
                        v-model="active" 
                        label="Aktywny"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn btn-outline-primary">
            Anuluj
          </div>
          <div class="btn btn-primary" @click="handleSubmitForm">
            Zapisz zmiany
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
  import { mapState, mapActions, mapMutations } from 'vuex';
  
  const VuexMappingFactory = (computedName, vuexMapping, nullishFallback = '' ) => {
    const getValue = (obj, path) => {
      if (!path) return obj;
      const properties = path.split('.');
      return getValue(obj[properties.shift()], properties.join('.'))
    }
    try {
      return {
        [computedName]: {
          get() {
            return getValue(this.newsletterInformation, vuexMapping) ?? nullishFallback;
          },
          set(newVal) {
            this.updateNewsletterField({field: vuexMapping, value: newVal});
          }
        }
      }
    } catch (e) {
      return false;
    }
  }
  
  export default {
    name: 'NewsletterView',
    components: {
      BreadcrumbHeader,
    },
    data: () => ({
      active: true
    }),
    computed: {
      ...mapState('NewsletterViewModule', ['newsletterInformation', 'apiStatus', 'postStatus' ]),
      breadcrumb() {
        return [
          {title: 'Sklep', link: {name: 'store'}},
          {title: 'Subskrybenci Newslettera', link: {name: 'product-category-list'}},
        ];
      },
      ...VuexMappingFactory('email', 'email', ''),
      ...VuexMappingFactory('active', 'active', true),
      active: {
      get() {
        return this.couponInformation.active
      },
      set(newVal){
        this.updateCouponField({
          field: 'active',
          value: newVal
        })
      }
    },
    },
    methods: {
      ...mapMutations('NewsletterViewModule', ['updateNewsletterField', 'resetCategoryInfo']),
      ...mapActions('NewsletterViewModule', ['fetchNewsletterInformation', 'submitAddForm']),
      async handleSubmitForm() {
        const handleCategoryId = this.$route?.params?.id ? `/${this.$route.params.id}` : '';
        await this.submitAddForm(handleCategoryId);
      }
    },
    async mounted() {
      if (this.$route.params.id) {
        await this.fetchNewsletterInformation(this.$route.params.id);
      }
    },
    beforeDestroy() {
      this.resetCategoryInfo();
    },
    watch: {
      $route(to, from) {
        if ( to !== from ) {
          this.resetCategoryInfo();
        }
      }
    }
  };
  </script>
  <style>
  .page-loading {
    opacity: 0.5;
  }
  </style>
  